import { gql } from '@apollo/client';

export const LIST_ALL_BUSINESSES = gql`
    query ListBusiness($input: ListBusinessDTO!) {
        listBusiness(input: $input) {
            data {
                businessName
                abn
                dateJoined
                email
                phone
                status
                _id
            }
            pagination {
                hasNextPage
                total
            }
        }
    }
`;

export const GET_BUSINESS_DETAILS_QUERY = gql`
    query GetBusinessDetails($getBusinessDetails: BusinessDetailsDTO!) {
        getBusinessDetails(getBusinessDetails: $getBusinessDetails) {
            abn
            businessName
            otherLocations {
                _id
                email
                locationAddress {
                    city
                    coordinates
                    country
                    displayLocation
                    postalCode
                    state
                    street
                    type
                }
                name
                phone
            }
            primaryLocation {
                _id
                email
                locationAddress {
                    city
                    coordinates
                    country
                    displayLocation
                    postalCode
                    state
                    street
                    type
                }
                name
                phone
            }
            userDetails {
                _id
                email
                fullName
                phone
                profilePicture
                firstName
                lastName
                dateOfBirth
            }
            logo
        }
    }
`;

export const GET_DASHBOARD_BUSINESS_COUNT_QUERY = gql`
    query GetDashboardBusinessCount {
        getDashboardBusinessCount {
            disabledBusiness
            subscribedBusiness
            trialBusiness
        }
    }
`;

export const GET_DASHBOARD_REVENUE_COUNT_QUERY = gql`
    query GetDashboardBusinessRevenue {
        getDashboardBusinessRevenue {
            lastMonthCount
            lastQuarterCount
            lastYearCount
        }
    }
`;

export const BUSINESS_SUBSCRIPTION_DETAIL_QUERY = gql`
    query BusinessSubscriptionDetail($businessSubscriptionDetail: BusinessDetailsDTO!) {
        BusinessSubscriptionDetail(BusinessSubscriptionDetail: $businessSubscriptionDetail) {
            maximumValue
            minimumValue
            trialExpiresOn
            subscription {
                nextPaymentDate
                status
                totalPractitioners
            }
            businessSubscriptionStatus
        }
    }
`;

export const BUSINESS_SUBSCRIPTION_HISTORY_QUERY = gql`
    query BusinessSubscriptionHistory($businessSubscriptionHistory: BusinessSubscriptionDTO!) {
        BusinessSubscriptionHistory(BusinessSubscriptionHistory: $businessSubscriptionHistory) {
            data {
                _id
                amount
                paidDate
                businessId
                maximumValue
                minimumValue
            }
            pagination {
                hasNextPage
                total
            }
        }
    }
`;

export const EXPORT_BUSINESSES_QUERY = gql`
    query ExportBusinessList {
        exportBusinessList {
            fileUrl
        }
    }
`;
