/* eslint no-underscore-dangle: 0 */
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material';

import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import dayjs from 'dayjs';
import useGQL from 'hooks/useGQL';

import Spinner from 'components/spinner';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArrangementOrder1 } from 'types';
import { centToDollar } from 'utils/price';
import { headCellsMebershipHistory } from '../constants/types';

const MembershipHistory = () => {
    const [order, setOrder] = useState<ArrangementOrder1>('asc');
    const [orderBy, setOrderBy] = useState<string>('_id');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [pageMeta, setPageMeta] = useState<{ limit: number; skip: number }>({ limit: 5, skip: 0 });

    const { BUSINESS_SUBSCRIPTION_HISTORY } = useGQL();
    const params = useParams();

    const { error, loading, data, refetch } = BUSINESS_SUBSCRIPTION_HISTORY({
        businessId: params.id,
        pageMeta: {
            skip: page > 0 ? rowsPerPage * page : 0,
            limit: rowsPerPage
        },
        searchText: null
    });

    const [membershipHistoryList, setMembershipHistoryList] = useState<any>();

    useEffect(() => {
        setMembershipHistoryList(data?.BusinessSubscriptionHistory?.data);
    }, [data?.BusinessSubscriptionHistory]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        if (event?.target?.value) {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        }
    };

    return loading ? (
        <Spinner />
    ) : (
        <>
            <Typography variant="h3" mt={2.5} ml={2}>
                Membership History
            </Typography>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {headCellsMebershipHistory.map((headCell) => {
                                return (
                                    <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                        {membershipHistoryList?.length != 0 && (
                            <>
                                {membershipHistoryList?.map((business) => (
                                    <TableRow key={business._id}>
                                        <TableCell>
                                            {business.minimumValue} - {business.maximumValue}
                                        </TableCell>
                                        <TableCell>{centToDollar(business?.amount || 0)}</TableCell>
                                        <TableCell>{dayjs(business.paidDate).format('DD/MM/YY')}</TableCell>
                                    </TableRow>
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {membershipHistoryList?.length === 0 && (
                <Typography variant="h1" align="center">
                    No items
                </Typography>
            )}
            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[10, 25, 30]}
                component={Paper}
                count={data?.BusinessSubscriptionHistory?.pagination?.total || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default MembershipHistory;
